import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import '../../css/Popup.css'

function CoinStatusPopup({closeWindow, status, setNextMode, statusesList, page = 1}){
    const[cashbackArr, setCashbackArr] = useState([{text:'Менее 20 000', num:status?.min}, {text:'Менее 40 000', num:Number(status?.min)*2}, {text:'Менее 60 000', num:Number(status?.min)*3}, {text:'Менее 80 000', num:Number(status?.min)*2 + Number(status?.min)*3}, {text:'Менее 150 000', num:Number(status?.min)*4 + Number(status?.min)*3}, {text:'Более 150 000', num:status?.max}]); // формула: мин, минХ2, минХ3, минХ2+минХ3, минХ4+минХ3, макс

    return(
        <div className="popup-place">
            <OutsideClickHandler onOutsideClick={() => {closeWindow(false); setNextMode(false)}}>
                <div className="popup-main-part">
                <div className="popup-close-but" onClick={() => {closeWindow(false); setNextMode(false)}}></div>
                    {page == 1
                    ?<><div className="popup-top-part">
                        <div className={`img status_${status?.num}`}></div>
                        <div className="popup-header">
                            <div>Правила начисления в статусе:</div>
                            <div>{status?.name}</div>
                        </div>
                    </div>
                    <div className="popup-info-part">
                        <div className="table-header-part">
                            <div className="table-header">Цена товара</div>
                            <div className="table-header">Кэшбек</div>                            
                        </div>
                        {cashbackArr.map((item:any, index:number) =>
                            <div key={index}>
                                <div>{item.text}</div>
                                <div className="table-coin-part">{`+${item.num} KrakenCoin`}</div>
                            </div>
                        )}
                    </div></>

                    :<><div className="popup-top-part">
                        <div className="popup-header-info-img"></div>
                        <div className="popup-header">Как получить статус выше?</div>
                    </div>
                    <div className="popup-info-part all-statuses">
                        <div className="table-header-part">
                            <div>Статус</div>
                            <div>Заказов</div>
                            <div>Мин. кэшбек</div>
                            <div>Макс. кэшбек</div>
                        </div>
                        {statusesList?.map((item:any, index:number) =>
                            <div key={index} className="all-statuses-table-main">
                                <div className={`status_${index}`}>{item?.name}</div>
                                <div>{item?.orderCount}</div>
                                <div className="table-coin-part">{`+${item?.min}`}</div>
                                <div className="table-coin-part">{`+${item?.max}`}</div>
                            </div>
                        )}
                    </div></>
                    }
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default CoinStatusPopup;