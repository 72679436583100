import React, { useEffect, useState } from "react";
import classes from './PageSwitcher.module.css';

function PageSwitcher({availPage, setAvailPage, items, onPageItemsCount = 20}){
    const[showPage, setShowPage] = useState({first:0, last:0});

    useEffect(() => {
        console.log(availPage)
        if(items == undefined) return

        let firstly = 0, lastly = 0;
        if(availPage > 1){
            firstly = availPage-2
        }else{
            firstly = 0
        }

        if(firstly+4 < availPage*onPageItemsCount){
            lastly = firstly+4
        }else{
            lastly = (items.length - items.length%onPageItemsCount)/onPageItemsCount + 1
        }

        setShowPage({first:firstly, last:lastly})
    }, [items, availPage])


    function setPage(direction, pageNum = null){
        if(items == undefined || items.length == 0) return

        if(pageNum != null){
            if(direction == 'forward' && items.length > pageNum*onPageItemsCount){
                setAvailPage(pageNum)
            }
            return
        }

        if(direction == 'backward' && availPage > 0){
            setAvailPage(availPage-1)
        }else if(direction == 'forward' && items.length > availPage*onPageItemsCount){
            setAvailPage(availPage+1)
        }
    }



    return(
        <div className={classes.page_switcher}>
            <div onClick={() => setPage('backward')}>{'< Назад'}</div>

            <div onClick={() => setPage('forward', showPage.first)}  className={showPage.first == availPage || availPage == undefined ?classes.active :''}>{showPage.first+1}</div>
            {showPage.last >= showPage.first+1
                ?<div onClick={() => setPage('forward', showPage.first+1)} className={showPage.first+1 == availPage ?classes.active :''}>{showPage.first+2}</div>
                :<></>
            }
            {showPage.last >= showPage.first+2
                ?<div onClick={() => setPage('forward', showPage.first+2)}  className={showPage.first+2 == availPage ?classes.active :''}>{showPage.first+3}</div>
                :<></>
            }
            {showPage.last >= showPage.first+3
                ?<div onClick={() => setPage('forward', showPage.first+3)}  className={showPage.first+3 == availPage ?classes.active :''}>{showPage.first+4}</div>
                :<></>
            }
            {showPage.last >= showPage.first+4
                ?<div onClick={() => setPage('forward', showPage.first+4)}  className={showPage.first+4 == availPage ?classes.active :''}>{showPage.first+5}</div>
                :<></>
            }

            <div onClick={() => setPage('forward')}>{'Вперед >'}</div>
        </div>
    )
}

export default PageSwitcher;