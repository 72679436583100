import React from "react";
import clssses from './PurpleBut.module.css'

type PurpleBut = {
    children: React.ReactNode;
    img_type?: string;
    onClick?: () => void;
    id?: string;
}

const PurpleBut: React.FC<PurpleBut> = ({ img_type, children, ...props }) => {
    return(
        <div className={clssses.purple_but} {...props}>
            <div className={img_type == 'cart' ?clssses.cart :(img_type == 'info' ?clssses.info :(img_type == 'order' ?clssses.order :''))}></div>
            <div>{children}</div>
        </div>
    )
}

export default PurpleBut;