import React from "react";
import classes from './MoreBut.module.css'
import { useNavigate } from "react-router-dom";

function MoreBut({to, needHide = true, hide}){
    const navigate = useNavigate();

    function click(){
        if(needHide){
            hide(false)
        }
        navigate(to)
    }

    return(
        <div className={classes.more_but} onClick={click}>Подробнее</div>
    )
}

export default MoreBut;