import React, { useState } from "react";
import '../../css/CatalogSectionsMenu.css'
import OutsideClickHandler from "react-outside-click-handler";

function CatalogSectionsMenu({outsideClick}){
    const[sections, setSection] = useState([
        {name:'Обувь для спорта', items:[
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
        ]},
        {name:'Тапки', items:[
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
        ]},
        {name:'Ботинки', items:[
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
        ]},
        {name:'Майки, кофты, рубашки', items:[
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
        ]},
        {name:'Сумки и рюкзаки', items:[
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
        ]},
        {name:'Кепки и шапки', items:[
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
        ]},
        {name:'Часы', items:[
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
            {icon:'', item_name:'Adidas Originals'},
        ]},
    ])
    const[mostSearchProducts, setMostSearchProducts] = useState(['кеды Nike', 'nike air jordan', 'rolex часы']);

    const faqLinks = [
        {name:'Оплата', link:''}, {name:'Доставка', link:''}, {name:'Поиск товара', link:''}, {name:'Помощь', link:''},
        {name:'Отзывы', link:''}, {name:'Команда', link:''}, {name:'Гарантии', link:''}, {name:'Для оптовиков', link:''}, 
        {name:'Для партнёров', link:''}, 
    ];


    function hideSection(index){
        if(document.getElementById(`section_hide_${index}`).style.display == 'none'){
            document.getElementById(`section_hide_${index}`).style.display = 'block'
        }else{
            document.getElementById(`section_hide_${index}`).style.display = 'none'
        }
    }


    return(
        <div className="catalog-menu-place">
            <OutsideClickHandler onOutsideClick={() => outsideClick(false)}>
                <div className="menu-sections-part">
                    <div className="section-names-catalog">
                        {sections.map((item, index) =>
                            <div className="catalog-section" key={index}>
                                <div className="catalog-section-header" id={`section_${index}`} onClick={() => hideSection(index)}>
                                    <h1>{item.name}</h1>
                                    <div></div>
                                </div>
                                <div style={{display:'none'}} id={`section_hide_${index}`}>
                                    {item.items.map((item, index) =>
                                        <div key={index}>{item.item_name}</div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="faq-links-section">
                        <h1>Покупателям</h1>
                        {faqLinks.map((item, index) => 
                            <div key={index}>{item.name}</div>
                        )}
                    </div>
                </div>
                <div className="menu-search-part">
                    <div className="search-bar">
                        <div></div>
                        <input
                            type="text"
                            placeholder="Начните вводить название товара"
                        />
                    </div>
                    <div className="most-search-things">
                        <h1>Сейчас также ищут:</h1>
                        <div className="most-search-items">
                            {mostSearchProducts.map((item, index) =>
                                <div key={index}>{item}</div>
                            )}
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default CatalogSectionsMenu;