import React, { useEffect, useState } from "react";
import classes from './AvailPath.module.css'
import { Link, useLocation } from "react-router-dom";

function AvailPath(){
    const[pathArrey, setPathArrey] = useState([]);

    const path_list = [
        {path:'order', name:'Оформление заказа'}, {path:'cart', name:'Корзина'}, {path:'product_page', name:'Страница товара'},
        {path:'invite_friend', name:'Приведи друга'}, {path:'find_product', name:'Подбор товара'}, {path:'', name:''},
        {path:'shoes', name:'Кроссовки'}, {path:'favorites', name:'Избранное'}, {path:'history', name:'Мои заказы'},
        {path:'status', name:'Мои баллы и статусы'}
    ]
    const location = useLocation();

    useEffect(() => {
        const path_arr = location.pathname.split('/')
        let new_arr = []
        path_arr.forEach(path => {
            // for(let i = 0; i < path_list.length; ++i){
            //     if(path == ''){
            //         new_arr.push('')
            //     }else if(path == path_list[i].path){
            //         new_arr.push(path_list[i])
            //     }
            // }
            if(path_list.findIndex(avail => avail.path == path) != -1){
                new_arr.push(path_list[path_list.findIndex(avail => avail.path == path)]    
                )
            }
        })
        setPathArrey([...new_arr])
    }, [location])

    return(
        <div className={classes.path_visualiser}>
            <div className={classes.path_icon}></div>
            <div className={classes.path_place}>
                {pathArrey.map((item, index) =>
                    <>{item.path != ''
                        ?<><div key={index} className={classes.path_item}>/</div><Link to={`/${item.path}`}><div className={classes.path_item}>{item.name}</div></Link></>
                        :<Link to='/' key={index}><div className={classes.path_item}>Главная</div></Link>
                    }</>  
                )}
            </div>
        </div>
    )
}

export default AvailPath;