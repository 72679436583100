import React, { useEffect, useState } from "react";
import '../../css/RecomendationCatalog.css';
import { CatalogHorizontal } from "./Catalog";
import { GrabNDragIcon } from "../UI/GrabNDragIcon/GrabNGragIcon";
import { axiosAsk } from "../../api/api";

function RecomendationPart({type, id}){
    const [availName, setAvailName] = useState(null);
    const [availArticles, setAvailArticles] = useState([]);

    const types = [['recomendation', 'МЫ РЕКОММЕНДУЕМ'], ['favorites', 'ИЗБРАННЫЕ ТОВАРЫ'], ['last', 'ВЫ НЕДАВНО ПРОСМАТРИВАЛИ']];

    useEffect(() => {
        if(availArticles.length){
            Slider(document.getElementById(id))
        }
    }, [availArticles])
    useEffect(() => {
        types.forEach(item => {
            if(item[0] == type){
                setAvailName(item[1])
            }
        })

        getArticleList()
    }, [type])

    function getArticleList(){
        if(type == 'recomendation'){
            axiosAsk('post', {
                oper:'get_list_by_search',
                ping:true,
                ask:'',
                categories:[],
                brands:[],
                // ask_type:text_sort,
                gen:Math.random() < 0.5,
                cost_min:0, 
                cost_max:10000000000,
            })
            .then(response => {
                setAvailArticles(response.data['articles'].slice(0, 10))
            })
            .catch(error => {console.error(error)})
        }else if(type == 'favorites'){
            axiosAsk('post', {
                oper:'get_likes',
                ping:true,
                phone:'-1',
            })
            .then(response => {
                setAvailArticles(response.data['likes_list'])
            })
            .catch(error => console.error(error))
        }else if(type == 'last'){

        }
    }

    function Slider(element){
        if(element == null || element == undefined) return

        const slider = element;
        let sliderStart = 0;
        let startX = 0;
        let startY = 0;
        let endX = 0;
        let endY = 0;
        let click = false;
        let outOfRange = false

        function setSliderPosition(position) {
            slider.children[0].style.left = position;
        }

        function animateSlider(){
            if(click){
                requestAnimationFrame(animateSlider)
                if(!outOfRange){
                    setSliderPosition(`${sliderStart + Number(endX-startX)}px`)
                }else{
                    setSliderPosition(`${sliderStart + Number(endX-startX)/1.25}px`)
                }
            }
        }
        function returnSlider(){
            let availPosition = Number(slider.children[0].style.left.replace('px', ''))

            slider.children[0].style.transition = 'left 0.3s ease'
            if(availPosition > 0){
                setSliderPosition(`${0}px`)
            }else if(availPosition < -(Number(slider.children[0].offsetWidth)-Number(window.innerWidth))-Number(244)){
                setSliderPosition(`${-(Number(slider.children[0].offsetWidth)-Number(window.innerWidth))-Number(244)}px`)
            }
        }

        slider.addEventListener('mousedown', (event) => startDrag(event))
        slider.addEventListener('touchstart', (event) => startDrag(event))
        function startDrag(event){
            slider.children[0].style.transition = 'none'
            startX = event.clientX
            endX = event.clientX
            sliderStart = Number(slider.children[0].style.left.replace('px', ''))
            click = true
            animateSlider();
        }

        window.addEventListener('mousemove', (event) => DragElement(event))
        window.addEventListener('touchmove', (event) => DragElement(event))
        function DragElement(event){
            if(click){
                endX = event.clientX

                if(!outOfRange && (sliderStart + Number(endX-startX) > 0 || sliderStart + Number(endX-startX) < -(Number(slider.children[0].offsetWidth)-Number(window.innerWidth))-Number(244))){
                    outOfRange = true
                    startX = event.clientX
                    endX = event.clientX
                    sliderStart = Number(slider.children[0].style.left.replace('px', ''))
                }else{
                    outOfRange = false
                }
            }
        }
        window.addEventListener('mouseup', (event) => DragEnd(event))
        window.addEventListener('touchend', (event) => DragEnd(event))
        function DragEnd(event){
            if(click){
                click = false
                endX = event.clientX
                returnSlider()
            }
        }
    }

    if(availArticles.length){
        return(
            <div className="recomendation-part">
                <div className="top-part">
                    <h1>{availName == undefined ?'' :availName}</h1>
                    <GrabNDragIcon/>
                </div>
                <div className="catalog-move-part" id={id}>
                    <CatalogHorizontal articles={availArticles}/>
                </div>
            </div>
        )   
    }
}

export default RecomendationPart;