import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import '../../css/Pages/CatalogPage.css'

import { CatalogVertical } from "../Parts/Catalog";
import AvailPath from "../UI/AvailPath/AvailPath";
import Sorter from "../UI/Sorter/Sorter";
import { axiosAsk } from "../../api/api";

function CatalogPage(){
    const[articles, setArticles] = useState(null);
    const[productCount, setProductCount] = useState(0);

    const[searchText, setSearchText] = useState(null);
    const[typeNames, setTypeNames] = useState(null);
    const[brandNames, setBrandNames] = useState(null);
    const[isManGender, setIsManGender] = useState(null);
    const[costMin, setCostMin] = useState(null);
    const[costMax, setCostMax] = useState(null);

    const sizes = ['35,5', '36', '36,5', '37', '37,5', '38', '38,5', '39', '39,5', '40', '40,5', '41', '41,5', '42', '42,5', '43', '43,5', '44', '44,5', '45', '45,5', '46', '46,5', '47', '47,5', '48', '48,5', '49', '49,5', '50', '50,5', '51', '51,5', '52']
    const path_list = [{path:'shoes', name:'Кроссовки'}]
    const location = useLocation();

    function KeyPress(e){
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();
            const inputs = document.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === document.activeElement) {
                if (i < inputs.length - 1) {
                    inputs[i + 1].focus();
                    break;
                }
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keypress', KeyPress);
        document.getElementById('scroll_wrapper').scrollTo({top:0})
        getArticlesList()
        // getBrandsList()

        return() => {
            window.removeEventListener('keypress', KeyPress)
        }
    }, [])



    function getArticlesList(){
        axiosAsk('post', {
            oper:'get_list_by_search',
            ping:true,
            ask:(searchText != null ?searchText :''),
            categories:(typeNames == null ?[] :typeNames),
            brands:(brandNames == null ?[] :brandNames),
            // ask_type:text_sort,
            gen:(isManGender == null ?true :!isManGender),
            cost_min:(costMin == null ?0 :costMin), 
            cost_max:(costMax == null ?10000000000 :costMax),
        })
        .then((response:any) => {
            setProductCount(response.data['articles'].length)
            setArticles(response.data['articles'])
        })
        .catch((error:Error) => {console.error(error)})
    }



    return(
        <div className="catalog-page">
            <AvailPath/>
            <h1>{path_list[0].name}</h1>
            <div className="catalog-brand-filter">

            </div>
            <div className="catalog-main-part">
                <div className="catalog-filters">
                    <div className="price-filter">
                        <h2>Цена, ₽:</h2>
                        <div className="price-filter-main-part">
                            <div onClick={() => {document.getElementsByTagName('input')[0].focus()}}>от
                                <input type="text" onChange={(e) => setCostMin(!isNaN(Number(e.target.value)) ?e.target.value :costMin)} value={costMin}/>
                            </div>
                            <div onClick={() => {document.getElementsByTagName('input')[1].focus()}}>до
                                <input type="text" onChange={(e) => setCostMax(!isNaN(Number(e.target.value)) ?e.target.value :costMax)} value={costMax}/>
                            </div>
                        </div>
                    </div>
                    <div className="size-filter">
                        <h2>Размеры:</h2>
                        <div className="size-filter-main-part">
                            {sizes.map((size, index) =>
                                <div key={index}><div>{size}</div></div>    
                            )}
                        </div>
                    </div>
                </div>
                <div className="catalog-place">
                    <div className="catalog-top-info">
                        <div className="product-counter">{`Найдено: ${productCount} позиций`}</div>
                        <Sorter/>
                    </div>
                    <CatalogVertical setProductCount={setProductCount} articles={articles}/>
                </div>
            </div>
        </div>
    )
}

export default CatalogPage;