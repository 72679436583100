import React, { useEffect, useState } from "react";
import '../../css/Pages/CartPage.css'
import { useNavigate } from "react-router-dom";
import { axiosAsk } from "../../api/api";

import AvailPath from "../UI/AvailPath/AvailPath";
import PurpleBut from "../UI/PurpleBut/PurpleBut";
import InCartProduct from "../Parts/StackItems/InCartProduct";
import RecomendationPart from "../Parts/RecomendationCatalog";

type CartPage = {
    cartCount:number;
    setCartCount:any;
}
function CartPage({cartCount, setCartCount}){
    const[cartProduct, setCartProduct] = useState([]);
    const[products, setProducts] = useState([]);
    const[totalCashBack, setTotalCashBack] = useState(0);
    const[cashBackArr, setCashBackArr] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if(cartProduct.length != cartCount){
            setCartCount(cartProduct.length)
        }

        setAllCashBack()
    }, [cartProduct])

    useEffect(() => {
        getFavorites();
    }, [])
    function getFavorites(){
        axiosAsk('post', {
            oper:'get_busket',
            ping:true,
            phone:'-1',
        })
        .then((response:any) => {
            setCartProduct(response.data['busket'])
        })
        .catch((error:Error) => console.error(error));
    }

    function deleteProd(article:string, size:string){
        console.log(cartProduct)
        let newArr = []
        cartProduct.forEach(item => {
            if(item.size != size || item.article != article){
                newArr.push(item)
            }
        })
        console.log(newArr)
        setCartProduct(newArr)
    }

    function setAllCashBack(){
        if(cartProduct.length == 0) return
        let idsArr = []
        cartProduct.forEach(item => {
            idsArr.push(item.article)
        })
        console.log(idsArr)

        axiosAsk('post', {
            oper:'get_cashback',
            ping:true,
            articles:idsArr
        })
        .then((response:any) => {
            setCashBackArr(response.data.cashback)

            let total_cashback = 0
            response.data.cashback.forEach((item:number) => {
                total_cashback += item
            });
            setTotalCashBack(total_cashback)
        })
        .catch((error:Error) => console.log(error))
    }

    return(
        <div className="cart-page">
            <AvailPath/>
            <div className="top-part">
                <h1>КОРЗИНА ТОВАРОВ</h1>
                <div>Очистить корзину</div>
            </div>
            {cartProduct.length
                ?<div className="cart-main-part">
                    <div className="in-cart-products">
                        {cartProduct.map((item, index) =>
                            <InCartProduct
                                key={index}
                                index={index}
                                article={item.article}
                                size={item.size}
                                deleteProd={deleteProd}
                                cashback={cashBackArr[index] == undefined ?0 :cashBackArr[index]}
                            />
                        )}
                    </div>
                    <div className="cart-order-info">
                        <div className="cart-order-price-info">
                            <div className="price-part">
                                <div>Стоимость заказа</div>
                                <div>{`${100} ₽`}</div>
                            </div>
                            <div className="cashback-part">
                                <div></div>
                                <div>{`+ ${totalCashBack} Кракенкоинов на счет`}</div>
                            </div>
                            <PurpleBut onClick={() => navigate('/order')}>Перейти к оформлению</PurpleBut>
                        </div>
                    </div>
                </div>
                :<div className="empty-cart">
                    <div className="text-part">
                        <div>В вашей корзине товаров пока нет(</div>
                        <div className="to-shopping-but" onClick={() => navigate('/shoes')}>За покупками</div>
                    </div>
                    <div className="image-part">
                        <img src="./img/png/cart-empty.png"/>
                    </div>
                </div>
            }
            <div className='cart-reconendations'>
                <RecomendationPart type='last' id='last-move-catalog'/>
                <RecomendationPart type='favorites' id='favorites-move-catalog'/>
                <RecomendationPart type='recomendation' id='recomendation-move-catalog'/>
            </div>
        </div>
    )
}

export default CartPage;