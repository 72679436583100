import React, { useEffect, useRef, useState } from "react";
import '../../css/Pages/OrderPage.css'
import AvailPath from "../UI/AvailPath/AvailPath";
import { axiosAsk } from "../../api/api";

import InOrderProduct from "../Parts/StackItems/InOrderProduct";
import YandexMap from '../Yandex/YandexMap'
import PurpleBut from "../UI/PurpleBut/PurpleBut";
import PriceLabel from "../UI/PriceLabel/PriceLabel";


function OrderPage(){
    const[cart, setCart] = useState([]);
    const[cashback, setCashback] = useState(0);
    const[logIn, setLogIn] = useState(false);
    const[paymentType, setPaymentType] = useState(0);
    const[personalInfo, setPersonalInfo] = useState({address:'', name:'', phone:''});

    useEffect(() => {
        getCart()
    }, [])
    function getCart(){
        axiosAsk('post', {
            oper:'get_busket',
            ping:true,
            phone:'-1',
        })
        .then((response:any) => {
            setCart(response.data['busket'])
        })
        .catch((error:Error) => console.error(error));
    }
    useEffect(() => {
        if(cart.length == 0) return

        axiosAsk('post', {
            oper:'get_cashback',
            articles:cart,
            ping:true,
            phone:'-1',
        })
        .then((response:any) => {
            let total = 0
            response.data.cashback.forEach((item:number) => {
                total += item
            })
            setCashback(total)
        })
        .catch((error:Error) => console.error(error));
    }, [cart])
    function Order(){
        if(paymentType == 0){
            alert('Выберите тип оплаты')
            return
        }

        let orderCart = []
        cart.forEach(item => {
            orderCart.push([item[0], true])
        })

        axiosAsk('post', {
            oper:'make_offer',
            ping:true,
            busket:orderCart,
            phone:'-1',
            name:personalInfo.name,
            is_login:logIn,
            is_full :(paymentType == 1 ?true :false),
            is_prepay_30:(paymentType == 2 ?true :false),
            is_prepay_10:(paymentType == 3 ?true :false),
        })
        .then((response:any) => {
            console.log(response.data)
        })
        .catch((error:Error) => console.error(error));
    }


    return(
        <div className="order-page">
            <AvailPath/>
            <h1>ОФОРМЛЕНИЕ ЗАКАЗА</h1>
            <div className="order-main-part">
                <div className="order-person-info">
                    <div>
                        <h2>Доставка</h2>
                        <div className="delivery-radio-part">
                            <label>
                                <input type="radio" name="delivery-type"/>
                                <div className="order-block delivery-item">
                                    <div className="icon-part pvz"></div>
                                    <div className="text-part">
                                        <div>Забрать в пункте выдачи</div>
                                        <div>СДЕК</div>
                                    </div>
                                </div>
                            </label>
                            <div className="flex-empty-helper"></div>
                            <label>
                                <input type="radio" name="delivery-type"/>
                                <div className="order-block delivery-item">
                                    <div className="icon-part man"></div>
                                    <div className="text-part">
                                        <div>Курьером до двери</div>
                                        <div>СДЕК</div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="delivery-address-input">
                            <input type="text" className="order-block order-input" placeholder="Начните вводить адрес пункта или выберите на карте"/>
                        </div>
                        <div className="delivery-yandex-map">
                            <YandexMap></YandexMap>
                        </div>
                    </div>
                    <div>
                        <h2>Данные получателя</h2>
                        <div className="personal-info-block">
                            <div>
                                <input type="text" className="order-block order-input" placeholder="Ваше имя"/>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="order-block order-input"
                                    placeholder="+7(___)___-__-__"
                                    value={personalInfo.phone}
                                    onChange={(e) => (!isNaN(Number(e.target.value)) || e.target.value == '' ?e.target.value :personalInfo.phone)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2>Зарегистрируйтесь или войдите</h2>
                        <div className="login-block">
                            <input type="text" className="order-block order-input" placeholder="+7(___)___-__-__"/>                
                        </div>
                    </div>
                </div>
                <div className="order-cart-info">
                    <div>
                        <h2>Товар</h2>
                        <div className="order-cart-part">
                            {cart?.map((product, index) =>
                                <InOrderProduct index={index} key={index} article={product.article} size={product.size}/>
                            )}
                        </div>
                        {logIn
                        ?<>
                        <h2 className="h2-with-margin">Баллы сервиса</h2>
                        <div className="servece-bonus-part">
                            <label>
                                <input type="radio" name="cashback"/>
                                <div className="bonus-part-radio">
                                    <div className="bonus-first-text">СПИСАТЬ</div>
                                    <div className="bonus-cashback-text">
                                        <div>У вас на счету:</div>
                                        <PriceLabel>{`100`}</PriceLabel>
                                    </div>
                                </div>
                            </label>
                            <div className="bonus-part-or-devider">или</div>
                            <label>
                                <input type="radio" name="cashback"/>
                                <div className="bonus-part-radio">
                                    <div className="bonus-first-text">ЗАЧИСЛИТЬ</div>
                                    <div className="bonus-cashback-text">
                                        <div>Зачислится:</div>
                                        <PriceLabel>{`100`}</PriceLabel>
                                    </div>
                                </div>
                            </label>
                        </div>
                        </>
                        :<></>
                        }
                        <h2 className="h2-with-margin">Детализация расчёта</h2>
                        <div className="total-order-info">
                            <div className="total-number-part">

                            </div>
                            <div className="total-radio-part">
                                <div className="total-radio-text-part">Вы можете разделить оплату на несколько частей, чем больше процент оплаты сразу, тем больше скидка на товар. Остаток оплатите при получении в СДЭК.</div>
                                <div className="total-radio-divide-part">
                                    <label onClick={() => setPaymentType(1)}>
                                        <input type="radio" name="price-devide"/>
                                        <div className="total-radio-divide">
                                            <div><div>{`(Максимальная cкидка - 2 500 ₽)`}</div></div>
                                            <div>Оплатить 100%</div>
                                        </div>
                                    </label>
                                    <label onClick={() => setPaymentType(2)}>
                                        <input type="radio" name="price-devide"/>
                                        <div className="total-radio-divide">
                                            <div><div>(Скидка<br/> - 1 500 ₽)</div></div>
                                            <div>Оплатить 30%</div>
                                        </div>
                                    </label>
                                    <label onClick={() => setPaymentType(3)}>
                                        <input type="radio" name="price-devide"/>
                                        <div className="total-radio-divide">
                                            <div><div>(Скидка<br/> - 500 ₽)</div></div>
                                            <div>Оплатить 10%</div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <PurpleBut onClick={Order} id='order_butt' img_type="order">Оформить заказ</PurpleBut>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderPage;