import React, { useEffect, useState } from "react";
import '../../css/Pages/ProductPage.css';
import { useLocation } from "react-router-dom";
import { axiosAsk } from "../../api/api";

import AvailPath from "../UI/AvailPath/AvailPath";
import PurpleBut from "../UI/PurpleBut/PurpleBut";

type ProductPage = {
    cartCount:number;
    setCartCount:any;
}
function ProductPage({cartCount, setCartCount}){
    const[product, setProduct] = useState(null);
    const[liked, setLiked] = useState(false);
    const[imgs, setImgs] =  useState([]);
    const[sizes, setSizes] = useState([]);
    const[cashback, setCashback] = useState(0);

    const[availSize, setAvailSize] = useState(0);
    const[availPrice, setAvailPrice] = useState(0);
    const[availImg, setAvailImg] = useState(0);
    const[availDelivery, setAvailDelivery] = useState(-1);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const article = decodeURIComponent(params.get('art'));

    useEffect(() => {
        document.getElementById('scroll_wrapper').scrollTo({top:0})
    }, [])
    useEffect(() => {
        if(article == undefined || article == '') return

        getProductInfo()
    }, [article])

    async function getProductInfo(){
        axiosAsk('post', {
            oper:'get_cashback',
            ping:true,
            articles:[article]
        })
        .then((response:any) => {
            setCashback(response.data['cashback'])
        })
        .catch((error:Error) => console.log(error))

        let prod_info:any = await axiosAsk('post', {
            oper:'get_item_info',
            ping:true,
            article:article,
            phone:'-1'
        })
        .catch((error:Error) => console.error(error))

        if(prod_info.data['is_liked']){
            setLiked(true)
        }

        let newImgList = []
        for(let i = 1; i < prod_info.data['photo_cnt']+1; ++i){
            await axiosAsk('post', {
                oper:'get_photo_by_article',
                ping:true,
                article:article,
                num:i,
            })
            .then((response:any) => {
                newImgList[i-1] = response.data['photo']
            })
            .catch((error:Error) => console.error(error))
        }

        let newSizeList:any = await axiosAsk('post', {
            oper: 'get_sizes_by_article',
            ping:true,
            article:article,
        })
        .catch((error:Error) => console.error(error))


        setMinPrice(newSizeList.data['sizes'])
        setProduct(prod_info.data)
        setImgs(newImgList)
        setSizes(newSizeList.data['sizes'])
    }


    function nextImg(direction){
        if(direction == 'forward'){
            if(imgs.length > availImg+1){
                setAvailImg(availImg+1)
            }else{
                setAvailImg(0)
            }
        }else if(direction == 'backward'){
            if(availImg > 0){
                setAvailImg(availImg-1)
            }else{
                setAvailImg(imgs.length-1)
            }
        }
    }

    function setMinPrice(size_arr){
        let price = 10000000000;
        let size:any = ''
        size_arr.forEach((item:any) => {
            if(item[1] != '--' && item[1] != '-' && Number(item[1]) < Number(price)){
                price = item[1]
                size = item[0]
            }
            return
        })

        setAvailSize(size)
        setAvailPrice(price)
    }
    function setSize(size:any){
        let price:any = ''
        sizes.forEach(item => {
            if(item[0] == size){
                price = item[1]
            }
            return
        })

        setAvailSize(size)
        setAvailPrice(price)
    }

    function setLikedStatus(){
        if(liked){
            setLiked(false)
        }else{
            setLiked(true)
        }

        axiosAsk('post', {
            oper:(liked ?'unlike_item' :'like_item'),
            ping:true,
            article:article,
            phone:'-1',
        })
    }


    function addInCart(){
        if(availSize == 0){
            alert('Выберите размер')
            return
        }
        if(availDelivery == -1){
            alert('Выберите тип доставки')
            return
        }

        axiosAsk('post', {
            oper:'add_item',
            ping:true,
            article:article,
            size:availSize,
            type_of_delivery:(availDelivery == 1 ?true :false),
            phone:'-1',
        })
        .then(() => {
            setCartCount(cartCount+1)
            alert('Успешно добавлено в корзину')
        })
        .catch((error:Error) => console.error(error))
    }


    return(
        <div className="product-page">
            <AvailPath/>
            <div className="product-page-top-part">
                <div className="product-page-gallery-n-name-part">
                    <h1>{product?.name}</h1>
                </div>
                <div className="product-page-info-part">
                    <div className={liked ?'like-but checked' :"like-but"} onClick={setLikedStatus}></div>
                    <div className="krcoin-part">{`+${cashback} кешбэк за покупку`}</div>
                </div>
            </div>
            <div className="product-page-main-part">
                <div className="product-page-gallery-n-name-part">
                    <div className="gallery-side-items">
                        <div onClick={() => setAvailImg(availImg+1 < imgs.length ?availImg+1 :(availImg+1)-imgs.length)}>
                            <div>
                                {imgs.length
                                    ?<img src={`data:image/png;base64,${availImg+1 < imgs.length ?imgs[availImg+1] :imgs[(availImg+1)-imgs.length]}`}/>
                                    // ?<img src={`https://kamazaz.store:443/images/aacokwftzxwukhhlosieiawovblixz/photo_1.png`}/>
                                    :<></>
                                }
                            </div>
                        </div>
                        <div onClick={() => setAvailImg(availImg+2 < imgs.length ?availImg+2 :(availImg+2)-imgs.length)}>
                            <div>
                            {imgs.length
                                    ?<img src={`data:image/png;base64,${availImg+2 < imgs.length ?imgs[availImg+2] :imgs[(availImg+2)-imgs.length]}`}/>
                                    :<></>
                                }
                            </div>
                        </div>
                        <div onClick={() => setAvailImg(availImg+3 < imgs.length ?availImg+3 :(availImg+3)-imgs.length)}>
                            <div>
                            {imgs.length
                                    ?<img src={`data:image/png;base64,${availImg+3 < imgs.length ?imgs[availImg+3] :imgs[(availImg+3)-imgs.length]}`}/>
                                    :<></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="gallery-main-item">
                        <div className="navigate-gallery-part">
                            <div className="slider left" onClick={() => nextImg('backward')}></div>
                            <div className="slider" onClick={() => nextImg('forward')}></div>
                        </div>
                        <div className="img-box">
                            {imgs.length
                                ?<img src={`data:image/png;base64,${imgs[availImg]}`}/>
                                :<></>
                            }
                        </div>
                    </div>
                </div>
                <div className="product-page-info-part">
                    <div className="size-part">
                        <div className="header">Выберете размер (EU):</div>
                        <div className="info">Размер лучше посмотреть на язычке ваших кроссовок или померить стельки</div>
                        <div className="sizes">
                            {sizes?.map((item, index) =>
                                <label key={index}>
                                    {item[1] === '--' || item[1] === '-'
                                        ?<input type="radio" name="size-radio" disabled/>
                                        :<input type="radio" name="size-radio" onChange={() => setSize(item[0])}/>
                                    }
                                    <div className="size-item">{item[0].replace('EU ', '')}</div>
                                </label>
                            )}
                        </div>
                    </div>
                    <div className="delivery-part">
                        <div className="header">Выберете, как привезти:</div>
                        <div className="deliveries">
                            <label onClick={() => setAvailDelivery(0)}>
                                <input type="radio" name="delivery"/>
                                <div className="delivery-item">
                                    <div className="delivery-top-line">
                                        <div className="time-part">35-40 дней</div>
                                        <div className="price-part">{`${availPrice} ₽`}</div>
                                    </div>
                                    <div className="delivery-description">Привезем в Россию и отдадим в магазине в Москве или отправим СДЭК</div>
                                </div>
                            </label>
                            <label onClick={() => setAvailDelivery(1)}>
                                <input type="radio" name="delivery"/>
                                <div className="delivery-item">
                                    <div className="delivery-top-line">
                                        <div className="time-part">7-10 дней</div>
                                        <div className="price-part">{`${Number(availPrice)+5000} ₽`}</div>
                                    </div>
                                    <div className="delivery-description">Экспресс-доставка в Россию. Отдадим в Москве или СДЭКом</div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <PurpleBut img_type='cart' onClick={addInCart}>Добавить в корзину</PurpleBut>
                </div>
            </div>
            <div className="product-page-split-info-part">
                <div className="left-text-part">Оплачивайте 100% от стоимости и получайте <span>скидку в размере 2500 руб.</span></div>
                <div className="card-img-part"><img src="./img/png/credit-card.png"/></div>
                <div className="icon-part"></div>
                <div className="right-text-part">Рассчитать скидку на товар при предоплате частями</div>
            </div>
            <div className="product-page-description"></div>
            <div className="product-page-help-part">
                <div className="product-page-help-left">
                    <div className="img-place"><img src="./img/png/question-mark.png"/></div>
                    <div className="text-place">
                        <h1>НУЖНА ПОМОЩЬ ПО ТОВАРУ?</h1>
                        <div>Напишите нам в Telegram. Ответим на вопросы, поможем выбрать размер, посоветуем модели.</div>
                    </div>
                </div>
                <div className="product-page-help-right">
                    <div>
                        <div className="text-place">Перейти в Telegram </div>
                        <div className="tg-icon"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPage;