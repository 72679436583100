import React from "react";
import '../../css/Pages/ProductSearchPage.css'
import AvailPath from "../UI/AvailPath/AvailPath";

function ProductSearchPage(){
    return(
        <div className="search-prod-page">
            <div>
                <AvailPath/>
                <h1>ПОДБОР ТОВАРА</h1>
                <div className="search-body-text-part">Если вы не нашли нужный товар, вы можете обратиться за подбором в наш телеграм-канал. Мы постараемся найти для вас нужную позицию и привезти вам.</div>
                <div className="search-prods-tg-but">
                    <div>Перейти в Telegram</div>
                    <div className="tg-round"></div>
                </div>
            </div>
            <div>
                <div className="search-image">
                    <img src="./img/png/find_prod.png"/>
                </div>
            </div>
        </div>
    )
}

export default ProductSearchPage;