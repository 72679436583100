import React from "react";
import classes from './PriceLabel.module.css';

export default function PriceLabel({children}){
    return(
        <div className={classes.price_label}>
            <div className={classes.price_label_text}>{children}</div>
            <div className={classes.price_label_icon}></div>
        </div>
    )
}