import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../../css/Cards.css';
import RoundVector from "../../UI/RoundVector/RoundVector";
import { axiosAsk } from "../../../api/api";

export function BrandCard({item}){
    return(
        <div className="brand-card">
            <div className="image">
                <img src={item?.src}/>
            </div>
            <div className="name-text">{item?.brand}</div>
            <div className='price-text'>{`от ${item?.price} ₽`}</div>
            <div className="link-part">
                <RoundVector/>
                <div className="link-text-part">
                    <div>{`${item?.model_count} моделей`}</div>
                    <div>{`${item?.tov_count} товаров`}</div>
                </div>
            </div>
        </div>
    )
}

export function FinalBrandCard(){
    return(
        <div className="brand-card final">
            <div className="other-part">
                <div className="other-text">Дрегие бренды</div>
                <div className="other-vector"></div>
            </div>
            <div className="other-image"></div>
        </div>
    )
}





export function ProdCard({article, vertical = true}){
    const[id, setId] = useState(`catalog-card_${Math.random()*101}_${article}`)
    const[product, setProduct] = useState(null);
    const[liked, setLiked] = useState(false);
    const[name, setName] = useState('');
    const[imgs, setImgs] = useState([]);
    const[galleryHeight, setGalleryHeight] = useState(0);
    const[cashback, setCashback] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if(product == undefined || product.name == undefined){
            return
        }

        if(product.name.length > 30){
            let i = 42
            while(product.name[i] != ' '){
                --i
            }
            setName(product.name.slice(0, i).padEnd(i+3, '.'))
        }else{
            setName(product.name)
        }
    }, [product?.name])
    useEffect(() => {
        if(article != undefined){
            setProduct(null)
            setImgs([])
            setName('')
            getProductInfo(article)
            checkClick(document.getElementById(id))
        }
    }, [article])
    function getProductInfo(art){
        axiosAsk('post', {
            oper:'get_item_info',
            ping:true,
            article:art,
            phone:'-1'
        })
        .then((response:any) => {
            setProduct(response.data)
            if(response.data['is_liked']){
                setLiked(true)
            }
        })
        .catch((error:Error) => console.error(error))

        axiosAsk('post', {
            oper:'get_cashback',
            ping:true,
            articles:[article]
        })
        .then((response:any) => {
            setCashback(response.data['cashback'])
        })
        .catch((error:Error) => console.log(error))

        axiosAsk('post', {
            oper:'get_photo_by_article',
            ping:true,
            article:art,
            num:-1,
        })
        .then(response => {
            setImgs([response.data['photo']])
        })
        .catch(error => console.error(error))
    }

    function setLikedStatus(){
        if(liked){
            setLiked(false)
        }else{
            setLiked(true)
        }

        axiosAsk('post', {
            oper:(liked ?'unlike_item' :'like_item'),
            ping:true,
            article:article,
            phone:'-1',
        })
        .catch(error => console.error(error))
    }


    function checkClick(elem:any){
        elem.addEventListener('mousedown', (e:MouseEvent) => handleDown(e))
        elem.addEventListener('mouseup', (e:MouseEvent) => handleUp(e))

        let click = false
        let startX = 0

        function handleDown(e){
            if(e.target.className == 'like-but' || e.target.className == 'like-but checked') return

            click = true
            startX = e.clientX
            setTimeout(() => {
                click = false
            }, 100)
        }
        function handleUp(e){
            if(Math.abs(e.clientX - startX) > 5 || !click) return

            click = false
            navigate(`/product_page?art=${article}`)
        }
    }


    return(
        <div className={`prod-card${vertical ?' small_marg' :''}`}>
            <div id={id}>
                <div className={liked ?'like-but checked' :"like-but"} onClick={setLikedStatus}></div>
                <div className="prod-card-gallery" style={{height: galleryHeight-20}}>
                    {imgs?.map((img, index) =>
                        <img key={index} src={`data:image/png;base64,${img}`}/>             
                    )}
                </div>
                <div className="slide-counter"></div>
                <div className="prod-card-name">{name}</div>
                <div className="card-money-part">
                    <div className="prod-card-price">{`${product?.price == undefined ?0 :product?.price} ₽`}</div>
                    <div className='card-cr-coin-icon'>{`+ ${cashback[0]}`}</div>
                </div>
            </div>
        </div>
    )
}

export function FinalProdCard(){
    const[id, setId] = useState(`catalog-card_${Math.random()*101}_final`)
    const navigate = useNavigate();

    useEffect(() => {
        checkClick(document.getElementById(id))
    }, [])

    function checkClick(elem:any){
        elem.addEventListener('mousedown', (e:MouseEvent) => handleDown(e))
        elem.addEventListener('mouseup', (e:MouseEvent) => handleUp(e))

        let click = false
        let startX = 0

        function handleDown(e){
            if(e.target.className == 'like-but' || e.target.className == 'like-but checked') return

            click = true
            startX = e.clientX
            setTimeout(() => {
                click = false
            }, 100)
        }
        function handleUp(e){
            if(Math.abs(e.clientX - startX) > 5 || !click) return

            click = false
            navigate('/shoes')
        }
    }

    return(
        <div className="prod-card final">
            <div id={id}>
                <div className="final-card-text-part">
                    <div className="text-part">Все категории</div>
                    <div className="other-vector"></div>
                </div>
            </div>
        </div>
    )
}