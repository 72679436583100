import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { axiosAsk } from './api/api';
import './css/General.css'

import Header from './components/Parts/Main/Header';
import Footer from './components/Parts/Main/Footer';

import MainPage from './components/Pages/MainPage';
import ProductSearchPage from './components/Pages/ProductSearchPage';
import InviteFriendsPage from './components/Pages/InviteFriendsPage';
import CatalogPage from './components/Pages/CatalogPage';
import FavoritePage from './components/Pages/FavoritePage';
import OrderHystoryPage from './components/Pages/OrderHistoryPage';
import CoinStatusPage from './components/Pages/CoinStatusPage';
import ProductPage from './components/Pages/ProductPage';
import CartPage from './components/Pages/CartPage';
import OrderPage from './components/Pages/OrderPage';

function App() {
  const[cartCount, setCartCount] = useState(0);

  useEffect(() => {
    getCartCount()
  }, [])
  function getCartCount(){
    axiosAsk('post', {
      oper:'get_busket_counts',
      ping:true,
      phone:'-1',
    })
    .then((response:any) => {
      setCartCount(response.data['count'])
    })
    .catch((error:Error) => console.error(error))
  }

  return(
    <BrowserRouter>
      <div className='scroll_wrapper' id='scroll_wrapper'>
        <div className='wrapper'>
          <Header incartProdCount={cartCount}/>
          <main>
            <Routes>
              <Route path='/' element={<MainPage/>}/>
              <Route path='/find_product' element={<ProductSearchPage/>}/>
              <Route path='/invite_friend' element={<InviteFriendsPage/>}/>
              <Route path='/status' element={<CoinStatusPage/>}/>

              <Route path='/shoes' element={<CatalogPage/>}/>
              <Route path='/product_page' element={<ProductPage cartCount={cartCount} setCartCount={setCartCount}/>}/>
              <Route path='/cart' element={<CartPage cartCount={cartCount} setCartCount={setCartCount}/>}/>
              <Route path='/order' element={<OrderPage/>}/>
              <Route path='/favorites' element={<FavoritePage/>}/>
              <Route path='/history' element={<OrderHystoryPage/>}/>
            </Routes>
          </main>
        </div>
        <Footer/>
      </div>
    </BrowserRouter>
  )
}

export default App;
