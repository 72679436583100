import React, { useEffect, useState } from "react";
import '../../css/Pages/MainPage.css'
import { axiosAsk } from "../../api/api";

import { CatalogHorizontal } from "../Parts/Catalog";
import { FeaturesCardImg, FeaturesCardText } from "../UI/FeaturesCard/FeaturesCard";
import { RunningBanner } from "../UI/RunningBanner/RunningBanner";
import { BrandCard, FinalBrandCard } from "../Parts/StackItems/Cards";
import ProdsTypeCard from "../UI/ProdsTypeCard/ProdsTypeCard";
import { GrabNDragIcon } from "../UI/GrabNDragIcon/GrabNGragIcon";

function MainPage(){
    const[width, setWidth] = useState(0);
    const[articles, setArticles] = useState([]);

    const BrandCards = [
        {src:'./img/png/Air-Jordan-Simbolo.png', brand:'Nike', price:12000, tov_count:25000, model_count:15},
        {src:'./img/png/New-Balance-Simbolo.png', brand:'New Balance', price:12000, tov_count:25000, model_count:15},
        {src:'./img/png/Adidas-Simbolo.png', brand:'Adidas Originals', price:15000, tov_count:55000, model_count:45},
        {src:'./img/png/Asics-Simbolo.png', brand:'Asics', price:12000, tov_count:45000, model_count:23}
    ]
    const TypeCards = [
        {src: './img/png/type_card_1.png', name:'Бренды', count:123},
        {src: './img/png/type_card_2.png', name:'ТОП-обувь', count:82932},
        {src: './img/png/type_card_3.png', name:'Вся обувь', count:93282},
        {src: './img/png/type_card_7.png', name:'Одежда', count:123892},
        {src: './img/png/type_card_8.png', name:'Сумки', count:93464},
        {src: './img/png/type_card_4.png', name:'Аксессуары', count:25093},
        {src: './img/png/type_card_5.png', name:'Часы', count:12890},
        {src: './img/png/type_card_6.png', name:'Искусство', count:29786}
    ]


    function Slider(element){
        if(element == null || element == undefined) return

        const slider = element;
        let sliderStart = 0;
        let startX = 0;
        let startY = 0;
        let endX = 0;
        let endY = 0;
        let click = false;
        let outOfRange = false

        function setSliderPosition(position) {
            slider.children[0].style.left = position;
        }

        function animateSlider(){
            if(click){
                requestAnimationFrame(animateSlider)
                if(!outOfRange){
                    setSliderPosition(`${sliderStart + Number(endX-startX)}px`)
                }else{
                    setSliderPosition(`${sliderStart + Number(endX-startX)/1.05}px`)
                }
            }
        }
        function returnSlider(){
            let availPosition = Number(slider.children[0].style.left.replace('px', ''))

            slider.children[0].style.transition = 'left 0.3s ease'
            if(availPosition > 0){
                setSliderPosition(`${0}px`)
            }else if(availPosition < -(Number(slider.children[0].offsetWidth)-Number(window.innerWidth))-Number(244)){
                setSliderPosition(`${-(Number(slider.children[0].offsetWidth)-Number(window.innerWidth))-Number(244)}px`)
            }
        }

        slider.addEventListener('mousedown', (event) => startDrag(event))
        slider.addEventListener('touchstart', (event) => startDrag(event))
        function startDrag(event){
            console.log('err')
            slider.children[0].style.transition = 'none'
            startX = event.clientX
            endX = event.clientX
            sliderStart = Number(slider.children[0].style.left.replace('px', ''))
            click = true
            animateSlider();
        }

        window.addEventListener('mousemove', (event) => DragElement(event))
        window.addEventListener('touchmove', (event) => DragElement(event))
        function DragElement(event){
            if(click){
                endX = event.clientX

                if(!outOfRange && (sliderStart + Number(endX-startX) > 0 || sliderStart + Number(endX-startX) < -(Number(slider.children[0].offsetWidth)-Number(window.innerWidth))-Number(244))){
                    outOfRange = true
                    startX = event.clientX
                    endX = event.clientX
                    sliderStart = Number(slider.children[0].style.left.replace('px', ''))
                }else{
                    outOfRange = false
                }
            }
        }
        window.addEventListener('mouseup', (event) => DragEnd(event))
        window.addEventListener('touchend', (event) => DragEnd(event))
        function DragEnd(event){
            if(click){
                click = false
                endX = event.clientX
                returnSlider()
            }
        }
    }



    function getProductList(){
        axiosAsk('post', {
            oper:'get_list_by_search',
            ping:true,
            ask:'',
            categories:[],
            brands:[],
            // ask_type:text_sort,
            gen:Math.random() < 0.5,
            cost_min:0, 
            cost_max:10000000000,
        })
        .then((response:any) => {
            setArticles(response.data['articles'].slice(0, 10))
        })
        .catch((error:Error) => {console.error(error)})
    }
    function getPopularBrands(){
        // axiosAsk('post', {oper:'get_brands_list', ping:true})
        // .then(response => {
        //     console.log(response.data)
        // })
        // .catch(error => console.error(error))

        // axiosAsk('post', {oper:'get_top_brand_info', ping:true})
        // .then(response => {
        //     console.log(response.data)
        // })
        // .catch(error => console.error(error))
    }




    function handleResize(){
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        getProductList()
        Slider(document.getElementById('brand-slider'))
        Slider(document.getElementById('features-slider'))
        Slider(document.getElementById('row-catalog-top'))
        Slider(document.getElementById('row-catalog-luxe'))
        getPopularBrands()
        
        // axiosAsk('post', {oper:'get_brand_cost', ping:true, brands:['Jordan 5', 'Adidas']})
        // .then(response => {
        //     console.log(response.data)
        // })

        window.addEventListener('resize', handleResize)

        return() => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return(
        <div className="main-page">
            <div className="add-banner">
                <img src="./img/png/banner.png"/>
            </div>
            {/* <GrabNDragIcon/> */}
            <div className="feauteres-card-section" id="features-slider">
                <div>
                    <FeaturesCardText>Привезём из-за рубежа. При оформлении заказа вы можете выбрать авиа-экспресс за 7-10 дней или наземную доставку за 14-20 дней. А так же выбрать удобный вариант доставки СДЭК.</FeaturesCardText>
                    <FeaturesCardImg line1='КЕШБЭК' line2='до 5 000 ₽' src={'./img/png/discount-notification.png'} index={1}/>
                    <FeaturesCardImg line1='Гарантия' line2='ОРИГИНАЛА' src={'./img/png/certificate.png'} index={2}/>
                    <FeaturesCardImg line1='500 ТЫС +' line2='товаров в каталоге' src={'./img/png/package-list.png'} index={3}/>
                </div>
            </div>
            <div className="feaurures-section-text">
                <div className="feature-text">
                    <h1>У НАС ЕСТЬ ВСЁ!</h1>
                    <p><span>От всеми любимых Nike и Adidas до Gucci и Balenciaga, а так же редчайшие выпуски и эксклюзивные коллаборации.</span></p>
                    <p>А если вы не можете найти желаемый товар, то для зарегистрированных пользователей доступен <span>индивидуальный поиск товара за рубежом.</span></p>
                </div>
                <div className="feature-image">
                    <img src="./img/png/render-fin.png"/>
                </div>
            </div>
            <div className="onmain-catalog-section">
                <RunningBanner>ПОПУЛЯРНЫЕ БРЕНДЫ И КЛАССИКА СТИЛЯ</RunningBanner>
                <GrabNDragIcon/>
                <div className="catalog-move-part" id="row-catalog-top">
                    <CatalogHorizontal rowCount={2} articles={articles}/>
                </div>
            </div>
            <div className="onmain-catalog-section second">
                <RunningBanner>ЛЮКСОВЫЕ БРЕНДЫ, РЕДКИЕ АЙТЕМЫ И КОЛЛАБОРАЦИИ</RunningBanner>
                <GrabNDragIcon/>
                <div className="catalog-move-part" id="row-catalog-luxe">
                    <CatalogHorizontal rowCount={2} articles={articles}/>
                </div>
            </div>
            <div className="brand-catalog">
                <div>
                    <h1>ПОПУЛЯРНЫЕ МОДЕЛИ ОБУВИ ПО БРЕНДАМ</h1>
                    <GrabNDragIcon/>
                    <div className="brand-catalog-place" id="brand-slider">
                        <div>
                            {BrandCards.map((item, index) => 
                                <BrandCard key={index} item={item}/>
                            )}
                            <FinalBrandCard/>
                        </div>
                    </div>
                </div>
                <div className="mid-catalog-part">
                    <div></div>
                </div>
                <div>
                    <h1>ВСЕ КАТЕГОРИИ ТОВАРОВ</h1>
                    <div className="all-prods-type">
                        {TypeCards.map((item, index) => 
                            <ProdsTypeCard id={`type-card-${index}`} key={index} item={item}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainPage;