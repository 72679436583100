// import React, { useCallback, useContext, useEffect, useState } from "react";
// import {
//     YMap,
//     YMapComponentsProvider,
//     YMapDefaultSchemeLayer,
//     YMapDefaultFeaturesLayer,
//     YMapListener,
//     YMapFeature,
//     YMapCollection,
//     YMapControls,
//     YMapGeolocationControl,
//     YMapZoomControl,
//     YMapHint,
//     YMapDefaultMarker,
//     YMapContainer,
//     YMapControlButton,
//     YMapHintContext,
// } from "ymap3-components";
// import { location as LOCATION, features, apiKey } from "./helpers";
// import * as YMaps from "@yandex/ymaps3-types";
// import { LngLat } from "@yandex/ymaps3-types";

// function MyHint() {
//     const hint: any = useContext(YMapHintContext);
  
//     return (
//       <div className="hint">
//         <div className="hint-header">{hint?.hint}</div>
//         <div className="hint-hint">{hint?.hint}</div>
//         <div className="hint-desc">{hint?.hint}</div>
//       </div>
//     );
//   }
  
//   const MapLocation = ({ location }: any) => {
//     return (
//       <div className="map-info">
//         <div className="map-info-header">Center</div>
//         <div className="map-info-text">lat: {location.center[0].toFixed(2)}</div>
//         <div className="map-info-text">long: {location.center[1].toFixed(2)}</div>
//         <div className="map-info-header">Zoom</div>
//         <div className="map-info-text">zoom: {location.zoom.toFixed(2)}</div>
//       </div>
//     );
//   };

// export default function YandexMap() {
//     const[ymap, setYmap] = useState<YMaps.YMap>();
//     const[location, setLocation] = useState(LOCATION);
  
//     const onUpdate = React.useCallback(({ location, mapInAction }: any) => {
//       if (!mapInAction) {
//         setLocation({
//           center: location.center,
//           zoom: location.zoom,
//         });
//       }
//     }, []);
  
//     const getHint = useCallback((object: any) => object?.properties?.hint, []);
  
//     const zoomIn = useCallback(() => {
//       setLocation((location) => {
//         const newLocation = {
//           ...location,
//           zoom: location.zoom + 1,
//         };
  
//         return newLocation;
//       });
//     }, []);
  
//     const zoomOut = useCallback(() => {
//       setLocation((location) => {
//         const newLocation = {
//           ...location,
//           zoom: location.zoom - 1,
//         };
  
//         return newLocation;
//       });
//     }, []);

//     return (
//         // <YMapComponentsProvider apiKey={apiKey} lang="en_EN">
//         //     <YMap location={LOCATION} ref={(ymap: YMaps.YMap) => setYmap(ymap)}>
//         //         {/* <YMapDefaultSchemeLayer /> */}
//         //         {/* <YMapDefaultFeaturesLayer /> */}
//         //         {/* 
//         //         <YMapMarker coordinates={reactify.useDefault([37.588144, 55.733842])} draggable={true}>
//         //         <section>
//         //             <h1>You can drag this header</h1>
//         //         </section>
//         //         </YMapMarker> */}
//         //         {/* <Map defaultState={{ center: [55.751574, 37.573856], zoom: 9 }}>
//         //             <Placemark geometry={[55.751574, 37.573856]} />
//         //         </Map> */}
//         //     </YMap>
//         // </YMapComponentsProvider>

//       <YMapComponentsProvider apiKey={apiKey} lang="en_RU">
//         <YMap
//           key="map"
//           ref={(ymap: YMaps.YMap) => setYmap(ymap)}
//           location={location}
//           mode="vector"
//           theme="dark"
//         >
//           <YMapDefaultSchemeLayer />
//           <YMapDefaultFeaturesLayer />
//           <YMapListener onUpdate={onUpdate} />
//           {/* <YMapClusterer
//             method={gridSizedMethod}
//             features={[]}
//           >
//             {points?.map((point) => (
//               <YMapMarker coordinates={point} />
//             ))}
//           </YMapClusterer> */}
//           <YMapDefaultMarker coordinates={LOCATION.center} />
//           <YMapControls position="bottom">
//             <YMapZoomControl />
//           </YMapControls>
//           <YMapControls position="bottom left">
//             <YMapGeolocationControl />
//           </YMapControls>
//           <YMapHint hint={getHint}>
//             <MyHint />
//           </YMapHint>
//           <YMapCollection>
//             {features.map((feature) => (
//               <YMapFeature key={feature.id} {...feature} />
//             ))}
//           </YMapCollection>
//           <YMapControls position="top">
//             <YMapControlButton>
//               <div onClick={zoomIn} className="map-custom-button">
//                 Custom zoom in
//               </div>
//             </YMapControlButton>
//             <YMapControlButton>
//               <div onClick={zoomOut} className="map-custom-button">
//                 Custom zoom out
//               </div>
//             </YMapControlButton>
//           </YMapControls>
//           <YMapControls position="top left">
//             <YMapContainer>
//               <MapLocation location={location} />
//             </YMapContainer>
//           </YMapControls>
//         </YMap>
//       </YMapComponentsProvider>
//     );
// }


  // // For each object in the JS API, there is a React counterpart
  // // To use the React version of the API, include the module @yandex/ymaps3-reactify
  // const [ymaps3React] = await Promise.all([ymaps3.import('@yandex/ymaps3-reactify'), ymaps3.ready]);
  // const reactify = ymaps3React.reactify.bindTo(React, ReactDOM);
  // const {YMap, YMapDefaultSchemeLayer} = reactify.module(ymaps3);

  // export default function YandexMap() {
  //   return (
  //     // Initialize the map and pass initialization parameters
  //     <YMap location={LOCATION} showScaleInCopyrights={true} ref={(x) => (map = x)}>
  //       {/* Add a map scheme layer */}
  //       <YMapDefaultSchemeLayer />
  //     </YMap>
  //   );
  // }


// function loadMapScript() {
//   return new Promise((resolve, reject) => {
//     const script = document.createElement('script');
//     script.src = 'https://api-maps.yandex.ru/v3/?apikey=0a66f1a3-b052-49ca-8e0f-c1c13f0255c5&lang=en_RU&mode=debug';
//     script.onload = resolve;
//     script.onerror = reject;
//     document.head.appendChild(script);
//   });
// }

// export default function YandexMap(){
//   useEffect(() => {
//     let response = loadMapScript()
//     console.log(response)
//   }, [])

//   return(
//     <div></div>
//   )
// }




import React from 'react';
import { YMaps, Map, Placemark, ZoomControl, GeolocationControl } from 'react-yandex-maps';

function YandexMap() {
    const [zoom, setZoom] = React.useState(9);
    const mapState = React.useMemo(
        () => ({ center: [55.75, 37.57], zoom }),
        [zoom]
    );

    return (
        <YMaps>
            <Map defaultState={{ center: [55.753903, 37.620185], zoom: 9, controls: [], }} state={mapState}>
                <ZoomControl options={{ float: "left" }}/>
                <Placemark
                modules={["geoObject.addon.balloon"]}
                defaultGeometry={[55.75, 37.57]}
                properties={{
                    balloonContentBody:
                    "This is balloon loaded by the Yandex.Maps API module system",
                }}
                />
                <GeolocationControl options={{ float: "right" }} />
                <button onClick={() => setZoom((zoom) => (zoom === 9 ? 12 : 9))}>
                    Toggle map zoom
                </button>
            </Map>
        </YMaps>
    );
}

export default YandexMap;