import React, { useEffect, useState } from "react";
import '../../../css/InOrderProduct.css'
import { axiosAsk } from "../../../api/api";

type InOrderProduct = {
    article:string;
    size:string;
    index:number;
}
function InOrderProduct({article, size, index}){
    const[product, setProduct] = useState(null);
    const[img, setImg] = useState(null);
    const[name, setName] = useState('');

    useEffect(() => {
        getProduct()
    }, [article])
    useEffect(() => {
        if(product == undefined || product.name == undefined){
            return
        }

        if(product.name.length > 25){
            let i = 42
            while(product.name[i] != ' '){
                --i
            }
            setName(product.name.slice(0, i).padEnd(i+3, '.'))
        }else{
            setName(product.name)
        }
    }, [product?.name])

    async function getProduct(){
        if(article == null) return

        axiosAsk('post', {
            oper:'get_item_info',
            ping:true,
            article:article,
            phone:'-1'
        })
        .then((response:any) => {
            setProduct(response.data)
        })
        .catch((error:Error) => console.error(error))

        axiosAsk('post', {
            oper:'get_photo_by_article',
            ping:true,
            article:article,
            num:-1,
        })
        .then((response:any) => {
            setImg([response.data['photo']])
        })
        .catch((error:Error) => console.error(error))
    }

    return(
        <div className={index != 0 ?'in-order-product bordered' :"in-order-product"}>
            <div className="in-order-product-img">
                {img != undefined
                    ?<img src={`data:image/png;base64,${img}`}/>
                    :<></>
                }
            </div>
            <div className="in-order-product-text">
                <div className="product-name">{name}</div>
                <div className="product-delivery">Экспресс-доставка в Россию 7-10 дней.</div>
                <div className="product-price">{`${product?.price} ₽`}</div>
            </div>
        </div>
    )
}

export default InOrderProduct;