import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../css/Pages/OrderHistoryPage.css';

import AvailPath from "../UI/AvailPath/AvailPath";

function OrderHystoryPage(){
    const[orders, setOrders] = useState([]);

    const navigate = useNavigate()

    return(
        <div className="order-hystory-page">
            <AvailPath/>
            <h1>МОИ ЗАКАЗЫ</h1>
            {orders.length
                ?<div className="order-hystory">

                </div>
                :<div className="empty-hystory">
                    <div className="text-part">
                        <div>Вы пока ничего не заказывали!(</div>
                        <div className="to-shopping-but" onClick={() => navigate('/shoes')}>За покупками</div>
                    </div>
                    <div className="image-part">
                        <img src="./img/png/empty-hystory.png"/>
                    </div>
                </div>
            }
        </div>
    )
}

export default OrderHystoryPage;