import React from "react";
import AvailPath from "../UI/AvailPath/AvailPath";
import '../../css/Pages/InviteFriendsPage.css'
import PriceLabel from "../UI/PriceLabel/PriceLabel";

function InviteFriendsPage(){
    return(
        <div className="invivte-friends-page">
            <div className="invite-info-part">
                <AvailPath/>
                <h1>ПРИВЕДИ ДРУГА</h1>
                <div className="invite-link-part">
                    <div className="invite-link-text">ССЫЛКА НА РЕФЕРАЛЬНУЮ ПРОГРАММУ:</div>
                    <div className="invite-link-place">
                        <div className="invite-link">{'абчихба'}</div>
                        <div className="share-button"></div>
                    </div>
                </div>
                <div className="invite-text-flex-part">
                    <div>ДРУЗЕЙ ПРИГАШЕНО:</div>
                    <div className="invite-friend-count">{`${3} друга`}</div>
                </div>
                <div className="invite-text-flex-part">
                    <div>НАЧИСЛЕННО:</div>
                    <PriceLabel>{1200}</PriceLabel>
                </div>
            </div>
            <div className="invite-image-part">
                <img src="./img/png/gift-box.png"/>
            </div>
        </div>
    )
}

export default InviteFriendsPage;