import React, { useEffect, useState } from "react";
import { FinalProdCard, ProdCard } from "./StackItems/Cards";
import { axiosAsk } from "../../api/api";
import '../../css/Catalog.css'
import PageSwitcher from "../UI/PageSwitcher/PageSwitcher";

export function CatalogHorizontal({rowCount = 1, articles}){
    const[products, setProducts] = useState([]);

    useEffect(() => {
        function catalogLoad(){

        }
        catalogLoad()

    }, [])

    return(
        <div className="row-catalog" style={{
                    // gridTemplateRows: `repeat(${rowCount}, ${100/rowCount}%)`,
                    gridTemplateColumns: `repeat(${Math.floor(articles?.length/rowCount)+1}, ${100/(Math.floor(articles?.length/rowCount)+1)}%)`,
                    width: `${(Math.floor(articles?.length/rowCount)+1)*25}%`
                }}>
            {articles?.map((article:string, index:number) =>
                <ProdCard key={index} article={article}/>
            )}
            {articles
                ?<FinalProdCard/>
                :<></>
            }
        </div>
    )
}






export function CatalogVertical({setProductCount, articles}){
    const[onPageArticles, setOnPageArticles] = useState(null);
    const[availPage, setAvailPage] = useState(null);
    const[columnCount, setColumnCount] = useState(0);


    useEffect(() => {

        function handleResize(){
            if(window.innerWidth < 700){
                setColumnCount(1)
            }else if(window.innerWidth < 900){
                setColumnCount(2)
            }else if(window.innerWidth < 1200){
                setColumnCount(3)
            }else{
                setColumnCount(4)
            }
        }
        handleResize()

        window.addEventListener('resize', handleResize)
        return() => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    useEffect(() => {
        if(articles != undefined && articles.length != 0){
            setAvailArticlesList()
        }
    }, [articles])
    function setAvailArticlesList(){
        if(articles == undefined || articles.length == 0){
            return
        }

        if(availPage == null || availPage == 0){
            const arts = articles.slice(0, (articles.length > 20 ?20 :articles.length))
            setOnPageArticles(arts)
        }else{
            const arts = articles.slice(availPage*20, (articles.length > (availPage+1)*20 ?(availPage+1)*20 :articles.length))
            setOnPageArticles(arts)
        }
    }



    useEffect(() => {
        if(availPage == null) return

        document.getElementById('scroll_wrapper').scrollTo({top:0, behavior:'smooth'})
        setAvailArticlesList()
    }, [availPage])



    return(
        <div className="catalog-part">
            <div className="virtical-catalog" style={{gridTemplateColumns: `repeat(${columnCount}, ${100/columnCount}%)`}}>
                {onPageArticles?.map((item, index) =>
                    <ProdCard key={index} article={item}/>
                )}
            </div>
            <PageSwitcher availPage={availPage} setAvailPage={setAvailPage} items={articles}/>
        </div>
    )
}