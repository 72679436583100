import React, { useEffect, useState } from "react";
import '../../css/Pages/CoinStatusPage.css';

import AvailPath from "../UI/AvailPath/AvailPath";
import CoinStatusPopup from "../Parts/CoinStatusPopup";

function CoinStatusPage(){
    // От Макса нужен метод, который возвращает: кол-во заказов и кол-во коинов
    const[statusInfo, setStatusInfo] = useState({orderCount:4, balance:120}); // 
    const[availStatusBonus, setAvailStatusBonus] = useState(null);
    const[nextStatusBonus, setNextStatusBonus] = useState(null);
    const[ordersForNextLvl, setOrdersForNextLvl] = useState([0, 'товаров']);

    const[popup1IsOpen, setPopup1IsOpen] = useState(false);
    const[popup2IsOpen, setPopup2IsOpen] = useState(false);
    const[popupNextMode, setPopupNextMode] = useState(false);

    const statuses = [{name:'Новичок', orderCount:0, min:50, max:500, num:0}, {name:'Модник', orderCount:3, min:150, max:1500, num:1}, {name:'Профессионал', orderCount:10, min:250, max:2500, num:2}, {name:'Шопоголик', orderCount:20, min:350, max:3500, num:3}, {name:'Фешн-киллер', orderCount:30, min:500, max:5000, num:4}]

    useEffect(() => {
        setStatusBonus()
    }, [])


    function setStatusBonus(){
        let avail_num = -1
        statuses.forEach(item => {
            if((statusInfo.orderCount >= 30 || statusInfo.orderCount >= item.orderCount && statusInfo.orderCount < statuses[item.num+1].orderCount) && item.num > avail_num){
                setAvailStatusBonus(item)
                avail_num = Number(item.num)
            }

            if((avail_num+1 > 0) && avail_num+1 == item.num){
                setNextStatusBonus(item)
                if((item.orderCount - statusInfo.orderCount)%10 == 0 || ((item.orderCount - statusInfo.orderCount)%10 >= 5 && (item.orderCount - statusInfo.orderCount)%10 <= 9) || (item.orderCount - statusInfo.orderCount > 10 && item.orderCount - statusInfo.orderCount < 20)){
                    setOrdersForNextLvl([item.orderCount - statusInfo.orderCount, 'товаров'])
                }else if((item.orderCount - statusInfo.orderCount)%10 >= 2 && (item.orderCount - statusInfo.orderCount)%10 <= 4){
                    setOrdersForNextLvl([item.orderCount - statusInfo.orderCount, 'товара'])
                }else if((item.orderCount - statusInfo.orderCount)%10 == 1){
                    setOrdersForNextLvl([item.orderCount - statusInfo.orderCount, 'товар'])
                }
            }
        })
    }



    return(
        <>
            {popup1IsOpen
                ?<CoinStatusPopup setNextMode={setPopupNextMode} closeWindow={setPopup1IsOpen} statusesList={statuses} status={popupNextMode ?nextStatusBonus :availStatusBonus}/>
                :<></>
            }
            {popup2IsOpen
                ?<CoinStatusPopup page={2} setNextMode={setPopupNextMode} closeWindow={setPopup2IsOpen} statusesList={statuses} status={popupNextMode ?nextStatusBonus :availStatusBonus}/>
                :<></>
            }
            <div className="coin-status-page">
                <AvailPath/>
                <h1>МОИ БАЛЛЫ И СТАТУС</h1>
                <div className="status-page-devide-div">
                    <div className="coin-status-coin-part">
                        <div className="balance-part">
                            <h1>БАЛАНС:</h1>
                            <div className="balance"><span>{statusInfo?.balance}</span> KrakenCoin</div>
                        </div>
                        <div className="coins-history">
                            <h1>ИСТОРИЯ НАЧИСЛЕНИЙ:</h1>
                        </div>
                    </div>
                    <div className="coin-status-status-part">
                        <div className="avail-status">
                            <div className={`img status_${availStatusBonus?.num}`}></div>
                            <div className="info-part">
                                <div>СТАТУС:<span>{availStatusBonus?.name}</span></div>
                                <div>{`${availStatusBonus?.min}+ KrakenCoin за каждый заказанный товар`}</div>
                                <div>в зависимости от его стоимости</div>
                                <div className="more-info-but" onClick={() => setPopup1IsOpen(true)}>ПОДРОБНЕЕЕ</div>
                            </div>
                        </div>
                        {availStatusBonus?.num < 4
                            ?<>
                                <div className="status-pointer"></div>
                                <div className="next-status">
                                    <div className={`img status_${nextStatusBonus?.num}`}></div>
                                    <div className="info-part">
                                        <div>Закажи <span onClick={() => setPopup2IsOpen(true)}>{`ещё ${ordersForNextLvl[0]} ${ordersForNextLvl[1]}`}</span>{`, чтобы перейти на статус - ${nextStatusBonus?.name}`}</div>
                                        <div>{`От ${nextStatusBonus?.min} до ${nextStatusBonus?.max}+ KrakenCoin`}</div>
                                        <div className="more-info-but" onClick={() => {setPopup1IsOpen(true); setPopupNextMode(true)}}>ПОДРОБНЕЕЕ</div>
                                    </div>
                                </div>
                            </>
                            :<></>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoinStatusPage;