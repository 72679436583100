import React, { useEffect, useState } from "react";
import MainMenu from "./MainMenu";
import CatalogSectionsMenu from "../CatalogSectionsMenu";
import { useLocation, useNavigate } from "react-router-dom";

type Header = {
    incartProdCount?: number;
}

function Header({ incartProdCount }){
    const[menuIsOpen, setMenuIsOpen] = useState(false);
    const[sectionMenu, setSectionMenu] = useState(false);
    const[isMain, setIsMain] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    const avail_path = location.pathname

    function setMenuPosition(type){
        switch(type){
            case 'section':
                if(sectionMenu){
                    setSectionMenu(false)
                }else{
                    setSectionMenu(true)
                }
                return
            case 'main':
                if(menuIsOpen){
                    setMenuIsOpen(false)
                }else{
                    setMenuIsOpen(true)
                }
                return
        }
    }

    useEffect(() => {
        if(avail_path == '/'){
            setIsMain(true)
        }else{
            setIsMain(false)
        }
        // document.getElementById('main-menu').addEventListener('')
    }, [avail_path])

    return(
        <header className={!isMain ?"not-main-header" :''}>
            <div className="but-part">
                <div className="menu-burger-icon" onClick={() => setMenuPosition('section')}></div>
                {sectionMenu
                    ?<CatalogSectionsMenu outsideClick={setSectionMenu}/>
                    :<></>
                }
                <div className="search-icon"></div>
            </div>
            <div className="logo-part" onClick={() => navigate('/')}><h1>KRAKEN</h1></div>
            <div className="but-part">
                <div className="cart-icon" onClick={() => navigate('/cart')}>
                    <div className="number">{incartProdCount}</div>
                    <div className="icon"></div>
                </div>
                <div className="profile-icon" id="main-menu" onClick={() => setMenuPosition('main')}></div>
                {menuIsOpen
                    ?<MainMenu outsideClick={setMenuIsOpen}/>
                    :<></>
                }
            </div>
        </header>
    )
}

export default Header;