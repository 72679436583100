import React, { useEffect, useState } from "react";
import '../../css/Pages/FavoritePage.css'
import { useNavigate } from "react-router-dom";
import { axiosAsk } from "../../api/api";

import AvailPath from "../UI/AvailPath/AvailPath";
import { CatalogHorizontal } from "../Parts/Catalog";

function FavoritePage(){
    const[articles, setArticles] = useState([]);
    const[products, setProducts] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getFavorites();
    }, [])
    function getFavorites(){
        axiosAsk('post', {
            oper:'get_likes',
            ping:true,
            phone:'-1',
        })
        .then((response:any) => {
            setArticles(response.data['likes_list'])
        })
        .catch((error:Error) => console.error(error));
    }


    return(
        <div className="favorite-page">
            <AvailPath/>
            <div className="top-part">
                <h1>ИЗБРАННЫЕ ТОВАРЫ</h1>
                <div>Очистить избранное</div>
            </div>
            {articles.length
                ?<div className="favorite-products">
                    <CatalogHorizontal articles={articles}/>
                </div>
                :<div className="empty-favorite">
                    <div className="text-part">
                        <div>В вашем Избранном товаров пока нет(</div>
                        <div className="to-shopping-but" onClick={() => navigate('/shoes')}>За покупками</div>
                    </div>
                    <div className="image-part">
                        <img src="./img/png/favorite_empty.png"/>
                    </div>
                </div>
            }
        </div>
    )
}

export default FavoritePage;