import React, { useEffect, useState } from "react";
import '../../../css/InCartProduct.css'
import { axiosAsk } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import PriceLabel from "../../UI/PriceLabel/PriceLabel";

function InCartProduct({article, size, index, deleteProd, cashback}){
    const[product, setProduct] = useState(null);
    const[img, setImg] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getProductInfo()
    }, [article])

    function getProductInfo(){
        axiosAsk('post', {
            oper:'get_item_info',
            ping:true,
            article:article,
            phone:'-1'
        })
        .then((response:any) => {
            setProduct(response.data)
        })
        .catch((error:Error) => console.error(error))

        axiosAsk('post', {
            oper:'get_photo_by_article',
            ping:true,
            article:article,
            num:-1,
        })
        .then((response:any) => {
            setImg([response.data['photo']])
        })
        .catch((error:Error) => console.error(error))
    }
    function deleteProductFromCart(){
        axiosAsk('post', {
            oper:'delete_item_from_busket',
            ping:true,
            article:article,
            phone:'-1',
            size:size,
        })
        .then(() => {
            deleteProd(article, size)
        })
        .catch((error:Error) => console.error(error))
    }

    return(
        <div className={index != 0 ?'in-cart-product bordered' :"in-cart-product"}>
            <div className="in-cart-img-part">
                <div>
                    <img src={`data:image/png;base64,${img}`}/>
                </div>
            </div>
            <div className="in-cart-main-info">
                <div className="in-cart-top-line">
                    <div className="first-column">
                        <div className="name-part" onClick={() => navigate(`/product_page?art=${article}`)}>{product?.name}</div>
                        <div className="in-cart-size-part">
                            <div>Размер:</div>
                            <div>{size}</div>
                        </div>
                    </div>
                    <div className="second-column">
                        <PriceLabel>{`КЭШБЕК ${cashback}`}</PriceLabel>
                        <div className="in-cart-price-part">
                            <div>{`${product?.price} ₽`}</div>
                        </div>
                    </div>
                    <div className="third-column">
                        <div className="close-but" onClick={deleteProductFromCart}></div>
                    </div>
                </div>
                <div className="in-cart-delivery-info">
                    <div className="top-text-part">{`7-10 дней`}</div>
                    <div className="bottom-text-part">{`Экспресс-доставка в Россию. Отдадим в Москве или СДЭКом`}</div>
                </div>
            </div>
        </div>
    )
}

export default InCartProduct