import React from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import '../../../css/Menu.css'
import MoreBut from "../../UI/MoreBut/MoreBut";
import { Link, useNavigate } from "react-router-dom";

function MainMenu({outsideClick}){
    const navigate = useNavigate();

    return(
        <OutsideClickHandler onOutsideClick={() => outsideClick(false)}>
            <div className="main-menu-window">
                <div className="main-menu-coins">
                    <div>
                        <div className="main-menu-coins-coin-num">{1200}</div>
                        <div className="main-menu-coins-text">KrakenCoin</div>
                    </div>
                    <div className="main-menu-coins-icon"></div>
                </div>
                <div className="menu-status-part">
                    <div className="menu-status-icon"></div>
                    <div>
                        <div className="status-name">{`Статус: ${'шопоголик'}`}</div>
                        <div className="status-info">{`От ${50} до ${500} KrakenCpin`}</div>
                    </div>
                </div>
                <div className="main-menu-main-part">
                    <div className="menu-info-part">
                        <p>{`Закажи ещё ${3} товара, чтобы получить новый статус`}</p>
                        <MoreBut to='status' hide={outsideClick}/>
                    </div>
                    <Link to='/favorites' onClick={() => outsideClick(false)}><div className="menu-text-link-section">Избранное</div></Link>
                    <Link to='/history' onClick={() => outsideClick(false)}><div className="menu-text-link-section">Мои заказы</div></Link>
                    <Link to='/find_product' onClick={() => outsideClick(false)}><div className="menu-text-link-section">Подбор товара</div></Link>
                    <div className="menu-prize-info-part" onClick={() => {outsideClick(false); navigate('/invite_friend')}}>
                        <div className="menu-prize-icon"></div>
                        <div>
                            <div className="menu-prize-small-text">Приведа друга</div>
                            <div className="menu-prize-big-text">ПОЛУЧИ + 1 000 ₽</div>
                        </div>
                    </div>
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default MainMenu;