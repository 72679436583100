import React from "react";
import classes from './Sorter.module.css';

function Sorter(){
    return(
        <div className={classes.sorter_place}>
            <div className={classes.head_part}>
                <div>Сортировать по</div>
                <div></div>
            </div>
            <div className={classes.hide_part}>
                
            </div>
        </div>
    )
}

export default Sorter;